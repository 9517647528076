@import './variables';
@import './fonts';
@import './common';
@import '../components/CustomTable/custom-table';
@import '../components/Header/header';
@import '../components/NavigationFooter/navigation-footer';
@import '../components/Status/status';
@import '../components/Buttons/buttons';
@import '../components/CustomTable/custom-table';
@import '../screens/ActivateAccount/activate-account';
@import '../screens/AddCommerce/add_commerce.scss';
@import '../screens/SearchCommerce/search-commerce';
@import '../screens/AddCommerce/BankAccount/commerce-bank-account';
@import '../screens/AddCommerce/Beneficiaries/commerce-beneficiaries';
@import '../screens/AddCommerce/CommerceTerminals/commerce-terminals';
@import '../screens/AddCommerce/Conditions/commerce-conditions';
@import '../screens/AddCommerce/Documentation/commerce-documentation';
@import '../screens/AddCommerce/Summary/commerce-summary';
@import '../screens/AddCommerce/Contracts/contract_commerce';
@import '../screens/Login/login';
@import '../screens/ResetPassword/reset-password';
@import '../screens/SearchCommerce/search-commerce';
@import '../screens/Result/result';
@import '../screens/Logout/logout';

body {
  margin: 0;
  font-family: Santander-MicroText-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  min-height: 100vh;
  color: white;
  font-style: Santander-MicroText-Regular;

  .public-content {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: linear-gradient(to bottom right, $primary, $background-secondary);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    margin-top: 2.5rem;
    color: $grey;
    // min-height: 100vh;
  }
}
