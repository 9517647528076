.documentation {
  .input-title {
    color: $black;
    font-weight: bold;
  }

  .extra-documents {
    .extra-document-input {
      margin-top: 2em;
    }
  }

  .form-row {
    margin-top: 2.25rem;
  }

  @media (max-width: 767px) {
    .id-back-wrapper {
      margin-top: 1.25rem;
    }
  }
}
