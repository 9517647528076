.section-title {
  text-align: left;
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  color: #3e3e3e;
  text-transform: uppercase;
  opacity: 1;
}

.confirmation-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.confirmation-title {
  margin-top: 2.25rem;
  text-align: left;
  font: normal normal bold 20px Santander-Text-Bold;
  letter-spacing: 0;
  color: $black;
  text-transform: uppercase;
}

.subtitle {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}

.bordered-text {
  background-color: $background-clarification;
  border-radius: 5px;
  padding: 20px;
  border: 1px dashed darkgrey;
  font-weight: bold;
}

.label-text-editor {
  margin-bottom: 10px;
}