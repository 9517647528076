.status-container {
  margin-top: 2.625rem;

  .status-label {
    font: normal normal bold 20px Santander-Text-Bold;
  }

  .status-value {
    width: fit-content;
    font-size: 13px;
  }

  .status-red {
    color: $red-status;
    background-color: $red-status-background;
  }

  .status-yellow {
    color: $yellow-status;
    background-color: $yellow-status-background;
  }

  .status-green {
    color: $green-status;
    background-color: $green-status-background;
  }

  .status-border {
    margin: 1.5rem 0.75rem 0;
    border-bottom: 0.5px solid $dark-red-opaque;
  }
}
