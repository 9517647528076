.confirmation {
  margin-bottom: 10rem;

  .confirmation-card {
    padding-bottom: 0.625rem;

    border-bottom: 0.5px solid $dark-red-opaque;

    &.border-none {
      border-bottom: none;
    }

    .confirmation-title {
      margin-top: 2.25rem;
      text-align: left;
      font: normal normal bold 20px Santander-Text-Bold;
      letter-spacing: 0;
      color: $black;
      text-transform: uppercase;
    }

    .confirmation-subtitle {
      text-align: left;
    }

    .confirmation-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;
    }

    .info-fieldname {
      color: $black-pure;
    }

    .info {
      color: $black-pure;
      text-align: end;
    }

    .info.files {
      img,
      p {
        display: inline-block;
      }
      p {
        margin-left: 0.5rem;
        color: $black-pure;
      }
    }

    .confirmation-beneficiary-name {
      color: $black-pure;
    }

    .confirmation-laws {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  .table-wrapper {
    margin-block: 2.875rem 1rem;

    .table-category {
      color: white;
      display: flex;
    }

    .table-title {
      background: $table-heading-grey 0% 0% no-repeat padding-box;
      font-weight: bold;
    }

    .table-category,
    .table-title,
    .table-description {
      min-height: 45px;
      align-items: center;
    }

    .table-title,
    .table-description {
      padding-inline: 0.5rem;
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(4, calc(25% - 1rem));
      gap: 1rem;
      text-align: center;
    }

    @media (min-width: 768px) {
      .table-title,
      .table-description {
        padding-inline: 0;
      }
    }
  }

  .fee-checkbox-container {
    font-size: 150%;
    margin-top: 1.5em;
  }

  .fee-checkbox-label {
    margin-left: 1em;
  }

  .fee-checkbox {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
  }

  .comment-input {
    margin: 0;
    margin-top: 5%;
    background-color: $white;
    border: solid 1px $grey;
  }

  .comment-text {
    margin-top: 5%;
  }

  .display-inline {
    display: inline;
  }

  .tea-input {
    width: 6em;
    background-color: $white;
    border: solid 1px $grey;
    font-size: 0.5em;
    padding-top: 17px;
    margin-left: 2em !important;
  }

  .tea-text {
    vertical-align: middle;
    font-size: 1em;
    padding-bottom: 5px;
    padding-top: 7px;
  }

  .tea-group {
    margin-left: 0.5em;
  }

  .tea-container {
    font-size: 1.5em;
    margin-top: 2em;
  }

  .comment-showcase {
    color: $grey;
    font-size: 1em;
    background-color: $table-heading-grey;
    font-weight: bold;
    padding: 1em;
  }

  .comment-showcase-container {
    margin-top: 2em;
  }

  .comment-info {
    margin-top: 1em;
  }

  .comment-error {
    color: #ff0000;
  }
}

.bank-warning-text {
  margin-top: 1rem !important;
}

.bank-warning-title {
  font-weight: 600;
}

.bank-warning-icon {
  width: 10rem;
  height: auto;
}

.summary-title {
  font-family: Santander-Headline;
  font-size: 1.875rem;
  color: $primary;
  margin-top: 1.5rem;
  margin-bottom: 0.625rem;
}

.required {
  color: $primary;
}

.send-first-option {
  width: 12%;
}

.send-second-option {
  width: 25%;
}

.portal-access-mail-row {
  margin-top: 5vh;
}

.summary-phone-container {
  width: 50%;
  display: flex;
}

.summary-phone-code {
  width: 20%;
}

.summary-phone-number {
  width: 80%;
}