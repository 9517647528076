.table-wrapper {
  margin: 2.875em 0 1.875em;

  .table-category {
    background: #ffffff;
    color: #000000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.125em;
  }

  .table-title {
    background: $table-heading-grey 0% 0% no-repeat padding-box;
    font-weight: bold;
  }

  .table-category,
  .table-title,
  .table-description {
    min-height: 45px;
    align-items: center;

    .edit-input {
      border: none;
      width: 25%;
    }

    .codes-edit-input {
      border: none;
      width: 30%;
    }

    .codes-edit-input:focus-visible,
    .edit-input:focus-visible {
      outline: none;
    }

    .edit-tariff {
      height: 100%;
      border: 1px solid #d3d3d3;

      img {
        margin: 4px 10px 7px;
        height: 15px;
        width: 15px;
      }
    }
  }

  .table-title,
  .table-description {
    padding-inline: 0.5rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, calc(25% - 1rem));
    gap: 1rem;
    text-align: center;
  }

  .codes-table-cols {
    grid-template-columns: repeat(3, calc(33% - 1rem));
  }

  .table-separator {
    border: 0.5px solid $dark-red;
    margin: 2em 0;
  }

  @media (min-width: 768px) {
    .table-title,
    .table-description {
      padding-inline: 0;
    }
  }
}

.processor-title {
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
  line-height: 1.8125rem;
  color: $black;
  font-weight: bold;
}

.issuers-container {
  display: flex;
  justify-content: space-between;
}

.issuer-select {
  margin-top: 4rem;
  width: 49%;
}