h1 {
  font-family: Santander-Headline;
  font-size: 1.875rem;
  color: $primary;
  margin-top: 1.5rem;
  margin-bottom: 0.625rem;
}

p,
li,
label,
small {
  font-family: Santander-MicroText-Regular;
  color: $grey;
}

.semibold {
  font-weight: 600;
}

.form-control {
  background-color: $light-grey;
  border: none;
  border-radius: 0;
  margin-block: 0.9375rem 0.3125rem;
  padding: 0.625rem 0.9375rem;
  font-size: 0.875rem;
  font-family: Santander-MicroText-Regular;
  resize: none;
  color: $grey;
}

input.form-control {
  min-height: 2.8125rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: 1px solid $light-grey;
  -webkit-text-fill-color: $grey;
  -webkit-text-stroke-color: $grey;
  -webkit-box-shadow: 0 0 0px 1000px $light-grey inset;
}

input[readonly].form-control[readonly] {
  background: $disabled-grey;
  border: none;
  box-shadow: none;
  cursor: auto;
}

.form-control:disabled {
  background: $disabled-grey;
}

.card {
  padding: 3.125rem;
}

.card-header {
  background-color: transparent;
  border: none;
}

.primary-outlined-button {
  border-radius: 0;
  padding: 0.625rem 1.375rem;
  margin-top: 1.25rem;
  font-family: Santander-Text-Semibold;
  border: solid 1px $primary;
  color: $primary;
  background-color: $white;

  &:active,
  &:hover {
    border: solid 1px $primary;
    color: $white;
    background: $primary;
  }

  &:focus {
    border: solid 1px $primary;
    color: $primary;
    background-color: $white;
  }

  &.loading:active,
  &.loading:hover {
    background: $white !important;
  }
}

.btn.add-section {
  border: 1px solid $dark-red;
  background-color: $white;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0;
  color: $secondary;
  margin-top: 1.5rem;
  padding: 0.625rem 1.375rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
}

.btn.share-button {
  border: 1px solid $dark-red;
  background-color: $white;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0;
  color: $secondary;
  margin-top: 1.5rem;
  padding: 0.5rem 3rem;
  border-radius: unset;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }
}

.share-buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.navbar-light {
  background: $white;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
}

.navbar-username {
  padding: 0.5rem 1rem;
  color: $secondary;
}

.input-div {
  position: relative;
  width: 100%;
  height: 3rem;
  background-color: $white;
}

.select {
  height: 2.8125rem;
  margin-top: 0.9375rem;
  margin-bottom: 0.3125rem;
  background-color: $light-grey;
  border: none;
  padding: 0.75rem;
  color: $grey;
  font-size: 0.875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../assets/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;

  &:focus-visible {
    outline: 0;
    border-color: $input-focus-border;
    box-shadow: 0 0 0 0.25rem $input-focus-shadow;
  }

  &:disabled {
    background-color: $disabled-grey;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../assets/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    border: none;
    box-shadow: none;
  }

  &:disabled:focus {
    outline: 0;
    border-color: $white;
  }
}

.input-file-div {
  position: relative;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  img {
    margin-right: 0.625rem;
  }

  span {
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }

  p {
    text-align: center;
    color: $grey;
    margin: 0.5rem 0 0;
    font-size: 0.75rem;
  }

  .text-error {
    bottom: -1.5rem;
    left: 0;
  }
}

.input-file-label {
  color: $red;
  background-color: $white;
  font-size: 1.25rem;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1.25rem 2.5625rem;
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px dashed $grey-border;
}

.input-file-label.label-error {
  border-color: $error-orange;
}

.input-file-label.label-focus {
  border-color: $red;
}

.input {
  padding-bottom: 1.25rem;
  position: relative;
}

.input-title {
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0;
  color: $grey;
  margin-bottom: 0;
}

.required-asterisk {
  color: $primary;
}

.input-subtitle {
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
  line-height: 1.8125rem;
  color: $black;
  font-weight: bold;
}

.text-error {
  color: $error-orange;
  display: flex;
  justify-content: flex-start;
  inset: auto auto 0 calc(var(--bs-gutter-x) * 0.5);

  &.long-error {
    bottom: -1.125rem;
    left: 0;
  }
}

.text-error.form-text {
  font-size: 0.75rem;
}

label.form-label {
  margin-bottom: 0;
}

input[disabled].input-error,
.input-error {
  background-color: $light-error-orange;
}

.input-error:focus-within {
  background-color: $light-grey;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-top: 1.375rem;
  gap: 0.3125rem;

  .checkbox {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: $white;
    /* Not removed via appearance */
    display: grid;
    place-content: center;
    background-color: $white;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 0.15em;
    border: 0.15em solid $secondary;
    cursor: pointer;

    &:before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background: url('../assets/check-solid.svg') no-repeat;
      background-color: $secondary;
    }
    &:checked:before {
      transform: scale(1);
    }
  }
}

.form-check-input {
  margin-left: 0 !important;
  margin-right: 0.5rem;
  cursor: pointer;
}

.form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23fff'/%3e%3c/svg%3e") !important;
  background-color: $dark-red;
  border-color: $dark-red;
  box-shadow: none;
  filter: none;
}

.form-check-input:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23982e5b'/%3e%3c/svg%3e") !important;
  border-color: $dark-red;
}

.radio-button:not(:checked) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23fff'/%3e%3c/svg%3e") !important;
  border-color: $dark-red;
  box-shadow: none;
  filter: none;
}

.radio-button:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23982e5b'/%3e%3c/svg%3e") !important;
  border-color: $dark-red;
  background-color: transparent;
  background-size: 10px;
  background-position: center;
}

.form-check-input:focus {
  box-shadow: none;
  filter: none;
}

.form-switch,
.form-check {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.ecommerce-switch {
  padding-left: 0 !important;
}

.input-info {
  font-size: 0.8125rem;
}

.progress {
  border-radius: 50px;
  height: 0.3125rem;

  .progress-bar {
    background: linear-gradient(91deg, $primary, $background-secondary);
  }
}

.faTrashCan {
  cursor: pointer;
  border: 1px solid $grey;
  padding: 0.625rem 1.375rem;
}
