.bank-account {
  margin-bottom: 7rem;

  .buttons-container {
    display: flex;
    width: 100%;

    .add-buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;

      :first-child {
        margin-right: 2rem !important;
      }

      .add-button {
        margin: 1rem 0 0;
        padding: 0.4rem 2rem;
        color: $secondary;
        background-color: $white;
        border-color: $dark-red;
      }
    }
  }
}

.no-validation-title {
  color: $primary;
  font-size: 1.875rem;
  margin-top: 1rem;
  font-weight: 600;
}

.no-validation-text {
  color: $grey;
  font-size: 1.0625rem;
}