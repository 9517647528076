.information {
  min-height: 80vh;
  padding-bottom: 7rem;
}

.address,
.beneficiaries,
.branches,
.bank-account,
.documentation,
.commerce-terminals,
.conditions-and-tariff,
.contracts {
  min-height: 80vh;
  padding-bottom: 12rem;

  @media (min-width: 768px) {
    padding-bottom: 8rem;
  }
}

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;

  .input-subtitle {
    margin-bottom: 0;
  }

  &.with-icon {
    margin-bottom: 1rem;
  }
}

.beneficiaries .checkbox-label {
  margin-top: 0.25rem;
}
