.borderless-button {
  background-color: transparent;
  border: none;
  padding: 8px 60px;
  width: fit-content;
  color: $dark-red;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;

  img {
    margin-right: 10px;
  }
}

.borderless-button:hover {
  background-color: $secondary-light;
}
