.sent_contracts_box {
  border-bottom: solid 1px $dark-red;
}

.edit-input {
  border: none;
  width: 100%;
  text-align: right;
}

.edit-input:focus-visible {
  outline: none;
}

.edit-icon {
  cursor: pointer;
  border: solid 1px $grey-border;
  padding: 10px 20px;
}

.edit-icon img {
  height: fit-content;
}

.actions-container {
  margin-top: 100px;
}

.buttons-container {
  width: 80% !important;

  .defined-border {
    border: solid 1px $dark-red;
  }
}

.status-label {
  font: normal normal bold 20px Santander-Text-Bold;
}

.status-value {
  width: fit-content;
  font-size: 13px;
  min-width: 132px;
  text-align: center;
}

.status-red {
  color: $red-status;
  background-color: $red-status-background;
}

.status-yellow {
  color: $yellow-status;
  background-color: $yellow-status-background;
}

.status-green {
  color: $green-status;
  background-color: $green-status-background;
}

.status-border {
  margin: 1.5rem 0.75rem 0;
  border-bottom: 0.5px solid $dark-red-opaque;
}

.not-validated-header {
  padding: 20px;
}